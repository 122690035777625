import { Component, Prop } from 'vue-property-decorator';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { IWizardManager, IVideo } from '@/interfaces';

@Component
export default class StudioSettingsComponent extends StudioPageBase {
    options = {
    };

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    created() {
        super.created('studioSettings', true);
    }

    mounted() {
        super.mounted();
    }

    showExpired() {
        this.done();
    }

    showError(error: string, error2: string = null) {
        this.done();
    }

    done() {
        if (!this.wizard) return;

        this.wizard.back();
    }

    cancel() {
        if (!this.wizard) return;

        this.wizard.back();
    }

    editStudio() {
        if (!this.wizard) return;
        this.wizard.navigateTo('editStudio');
    }

    editLogo() {
        if (!this.wizard) return;
        this.wizard.navigateTo('editLogo');
    }

    editBackground() {
        if (!this.wizard) return;
        this.wizard.navigateTo('editBackground');
    }

    editCategories() {
        if (!this.wizard) return;
        this.wizard.navigateTo('categories');
    }

    managePermissions() {
        if (!this.wizard) return;
        this.wizard.navigateTo('permissions', 1000); // permissions needs to be a little bit wider
    }

    uploadVideos() {
        if (!this.wizard) return;
        this.wizard.navigateTo('uploadVideos', 1200);
    }

    turnOffPortal() {
        if (!this.wizard) return;
        this.wizard.navigateTo('turnOffPortal');
    }

    async resetStudioAssets(event: any = null) {
        Debug.log('resetStudioAssets starting');

        try {
            let response = await PlayerApi.resetStudioAssets(this.studio.studio);

            Debug.log("resetStudioAssets SUCCESS", this.studio.studio, response);
            Util.showToast('Reset all studio information for ' + this.studio.title);
            return true;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error("resetStudioAssets FAILED", this.studio.studio, err);
            Util.showToast('Failed to reset studio assets' + this.studio.title, true);
            return null;
        } finally {

        }
    }

}
